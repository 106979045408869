import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import PropTypes from "prop-types";

export default function BannerHero({ h1, intro }) {
  const { desktop } = useStaticQuery(
    graphql`
      query {
        desktop: file(relativePath: { eq: "images/common/hero.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 4160) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  );

  // Single Image Data
  const imageData = desktop.childImageSharp.fluid;

  return (
    <>
      <section>
        <BackgroundImage
          Tag="section"
          className="w-full flex items-center text-center relative"
          style={{ height: "calc(100vh - 200px)" }}
          fluid={imageData}
          backgroundColor={`#040e18`}
          title="Fullscreen Background"
          id="fullscreenbg"
          role="img"
          aria-label="Fullscreen Background"
          preserveStackingContext={true}
        >
          <div className="absolute opacity-50 bg-gray-800 top-0 bottom-0 right-0 left-0"></div>
          <div className="w-full relative text-white">
            <h1 className="font-bold text-2xl lg:text-4xl" itemProp="headline">
              {h1}
            </h1>
            <div className="border-primary border-t-2 w-32 h-1 mx-auto my-8"></div>
            <p className="text-lg text-gray-100 lg:max-w-3xl mx-auto">{intro}</p>
          </div>
        </BackgroundImage>
      </section>
    </>
  );
}

BannerHero.propTypes = {
  h1: PropTypes.string.isRequired,
  intro: PropTypes.string.isRequired,
};

BannerHero.defaultProps = {
  h1: "Test",
  intro: "Test",
};
