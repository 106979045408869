import React from "react";
import Layout from "../components/Layout";
import SEO from "../components/SeoHelper";
import { useTheme } from "../components/ThemeContext";
import Container from "../components/Container";
// import HeaderSimple from "../components/HeaderSimple";
import BannerHero from "../components/BannerHero";
// import CategoryBlocks from "../components/CategoryBlocks";
import BlogPanel from "../components/BlogPanel";
import Sidebar from "../components/Sidebar";
import HomePageSchemaData from "../components/HomePageSchemaData";

// eslint-disable-next-line
export default function HomepageTemplate({ pageContext: { posts, topPosts } }) {
  const { theme } = useTheme();
  const { home } = theme;
  const { h1, intro } = home;

  return (
    <Layout>
      <SEO title="Homepage" description={theme.description} />
      <BannerHero h1={h1} intro={intro} />
      <HomePageSchemaData />
      <Container>
        <section className="pt-8">
          <h2 className="text-2xl font-bold md:text-4xl">Latest Posts</h2>

          <div className="flex flex-wrap">
            <div className="flex flex-wrap w-full -mx-2 md:w-3/4 md:pr-16 lg:pr-24">
              <BlogPanel panel={posts} />
            </div>
            <div className="w-full md:w-1/4">
              <Sidebar />
            </div>
          </div>
        </section>
      </Container>
    </Layout>
  );
}
