import React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import Image from "gatsby-image";
// import routes from "../routes";
import { categoryToSlug } from "../common/toSlug";
import Button from "../components/Button";
import { formatDateFromTimestamp, toISOFromTimestampInSeconds } from "../common/dateFunctions";

export default function BlogPanel({ panel }) {
  return panel.map(
    ({
      id,
      metadata: {
        title,
        url,
        createdAt,
        // modifiedAt,
        description,
        // topPost,
        // categories,
        primaryCategory,
        // featuredImageUrl,
        processedFeatureImageUrl,
      },
    }) => {
      return (
        <article key={id} className="w-1/2 px-2 pt-4 mb-4 md:w-full lg:px-0 lg:pt-8 md:mb-6">
          <header className="flex flex-wrap">
            <div className="w-full md:w-1/2 lg:w-5/12">
              <Link title={title} className="block h-32 overflow-hidden zoom-image md:h-auto" to={url}>
                {processedFeatureImageUrl && (
                  <Image
                    alt={title}
                    className="max-w-full"
                    fluid={processedFeatureImageUrl}
                    style={{ width: "380px", height: "380px" }}
                  />
                )}
              </Link>
            </div>
            <div className="w-full mt-4 md:w-1/2 lg:w-7/12 md:pl-8 lg:pt-8">
              <div className="w-full">
                <Button small name={primaryCategory} url={`${categoryToSlug(primaryCategory)}/`}></Button>
                <Link to={url}>
                  <h2 className="my-3 text-sm font-bold leading-tight tracking-normal md:text-2xl lg:text-3xl hover:underline">
                    {title}
                  </h2>
                </Link>
                <p className="mb-3">
                  <Link to={url}>
                    <time
                      className="text-xs italic leading-none text-gray-700 md:text-sm"
                      dateTime={toISOFromTimestampInSeconds(createdAt)}
                    >
                      {formatDateFromTimestamp(createdAt)}
                    </time>
                  </Link>
                </p>

                <p className="hidden mb-6 transition hover:underline md:block">
                  <Link to={url} className="">
                    {description}
                  </Link>
                </p>

                <Link
                  to={url}
                  className="px-3 py-2 text-xs font-semibold tracking-wider text-white uppercase transition bg-primary hover:bg-primary-dark md:text-md"
                >
                  Read More &#8594;
                </Link>
              </div>
            </div>
          </header>
        </article>
      );
    }
  );
}
BlogPanel.propTypes = {
  panel: PropTypes.any.isRequired,
};
