import React from "react";
import { Helmet } from "react-helmet";
import { useTheme } from "../components/ThemeContext";
import { useLogo } from "../hooks/useLogo";

export default function HomePageSchemaData() {
  const { theme } = useTheme();
  const logo = useLogo();

  const structuredData = {
    "@context": "http://schema.org",
    "@type": "WebPage",
    mainEntityOfPage: {
      "@type": "Webpage",
      "@id": theme.urlWithProtocol,
    },
    headline: theme.title,
    //  potentialAction: {
    //    "@type": "SearchAction",
    //    target: "https://www.thespruce.com/search?q={search_term_string}",
    //    "query-input": "required name=search_term_string'",
    //  },
    datePublished: "2019-12-15",
    dateModified: "2019-12-15",
    publisher: {
      "@type": "Organization",
      name: theme.title,
      url: theme.urlWithProtocol,
      logo: { "@type": "ImageObject", url: `${theme.urlWithProtocol}${logo.src}` },
      brand: theme.title,
      publishingPrinciples: `${theme.urlWithProtocol}/about/`,
      sameAs: [],
      parentOrganization: {
        url: "https://zeyventures.com",
        brand: "ZeyVentures",
        name: "ZeyVentures",
      },
    },
    description: theme.description,
  };

  return (
    <>
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(structuredData)}</script>
      </Helmet>
    </>
  );
}
